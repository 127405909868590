import OnBoardingCtnr from "../../Components/OnBoardingCtnr/Index";
import Completed from "../../img/Completed.gif";
import Pending from "../../img/PendingTrans.gif";
import Failed from "../../img/Failed.gif";
import Loading from "../../img/loading.gif"
import { useDropzone } from 'react-dropzone';
import styles from "./css/index.module.css";
import { useState, useCallback, useEffect } from "react";
import { BASE_URL, UPLOAD_FILE_TO_TELEGRAM } from "../../Utils/Endpoint";
import * as signalR from "@microsoft/signalr";
import Button from "../../Components/Button/Index";
import { useLocation } from "react-router-dom";



const Processing = () => {

    const transaction = JSON.parse(localStorage.getItem('transaction') ?? '{}')
    const [preview, setPreview] = useState(null);
    const [textPreview, setTextPreview] = useState(null);
    const [response, setResponse] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [transID, setTransID] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [isUploaded, setIsUploaded] = useState(transaction?.POPSubmitted);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const transactionId = queryParams.get("transId");
    const [transactionState, setTransactionState] = useState(null);


    useEffect(() => {
        setIsValid(prev => (!!preview || !!textPreview) &&  !!transID)
    },[preview, textPreview, transID])

    useEffect(() => {

        let connection;

        try{
            connection = new signalR.HubConnectionBuilder()
          .withUrl(`${BASE_URL}hubs/QuickTransact?transactionId=${transactionId}`)
          .withAutomaticReconnect()
          .configureLogging(signalR.LogLevel.Information)
          .build();
    
        connection
          .start()
          .then(() => {
            console.log("SignalR connection established");
            // Additional setup if needed
            connection.on("SendTransactionStatus", (data) => {
                console.log("Received transaction update:", data);
                if(data !== "Pending"){
                    localStorage.removeItem("transaction")
                }
                setTransactionState(data);
              });
          })
          .catch(err => console.error("SignalR connection failed:", err));
        }
        catch(e){
            console.log(e)
        }
    
        
    
        // Clean up on component unmount
        return () => {
            if(!!connection)
                connection.stop().then(() => console.log("SignalR connection stopped"));
        };
      }, [transactionId]);

    const onDrop = useCallback((acceptedFiles) => {
        const file = new FileReader();
        setPreview(null);
        setTextPreview(null)
    
        file.onload = function() {
            console.log(acceptedFiles[0]);
            if(acceptedFiles[0].type.includes("image"))
                setPreview(file.result);
            else
                setTextPreview(acceptedFiles[0].name);
        }
    
        file.readAsDataURL(acceptedFiles[0])
      }, [])
    
      const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/*, .pdf'
      });

      async function handleOnSubmit() {
        //e.preventDefault();
    
        if ( typeof acceptedFiles[0] === 'undefined'){
            setErrorMessage("Image is not set.");
            return
        };

    
        const formData = new FormData();

    
        formData.append('FormFile', acceptedFiles[0]);
        formData.append('UserTransactionId', transID);
        formData.append('TransactionId', transactionId);


        //formData.append('api_key', import.meta.env.VITE_CLOUDINARY_API_KEY);
    
        const response = await fetch(BASE_URL + UPLOAD_FILE_TO_TELEGRAM, {
          method: 'POST',
          body: formData,
          headers : {
           // "Authorization" : "Bearer " + AuthCtx.token,
            'Accept': 'application/text'
        }
        });

        if(response.ok){
            setResponse("File Uploaded Successfully!");
            setErrorMessage('');
            setIsUploaded(true);
            localStorage.setItem("transaction",JSON.stringify({
                ...transaction,
                POPSubmitted: true
            }))
            return;
        }
    
        setResponse('');
        setErrorMessage("File not uploaded successfully.");
        return;
        //console.log('results', results);
      }


    return(<OnBoardingCtnr>
        {!(!!transactionState) ?
        <img src={Loading} alt={"loading"} style={{width: "150px"}}/>
        :
        <div className={styles.Processing}>
            <img src={transactionState === "Pending"? Pending : transactionState === "Approved" ? Completed : Failed} alt="Status"/>
            <h2>{transactionState === "Pending"? 'Pending!' : transactionState === "Approved" ? 'Approved!' : 'Declined!'}</h2>
            {!isUploaded && transactionState === "Pending" &&
            <div className={styles.UploadId}>
                <p>Please upload your proof of payment and input transaction ID.</p>
                <div className={styles.InputCtnr}>
                    <label className={styles.Label}>Transaction ID</label>
                    <div className={styles.DropdownCtnr}>
                        <input type="text" onChange={e => setTransID(e.target.value)} value={transID} />
                    </div>
                </div>
                <div className={styles.InputCtnr}>
                    <label className={styles.Label}>Upload document</label>
                  
                    <div {...getRootProps()} className={styles.Upload}>
                        <input {...getInputProps()} />
                        {preview === null && textPreview === null ?
                        <>
                        {
                            
                            isDragActive ?
                            <p>Drag 'n' drop or click to select file</p> :
                            <p>Drag 'n' drop or click to select file</p>
                        }</>
                        :
                        <div>
                            {textPreview === null ?
                            <img src={preview} alt="Upload preview" />
                            : 
                            <label>{textPreview}</label>
                            }
                        </div>}
                    </div>
                    
                </div>
                <span className={styles.ErrorMessage}>{errorMessage}</span>
                <span className={styles.SuccessMessage}>{response}</span>
                <div className={styles.Buttons}>
                    <Button
                        text={"Submit"}
                        isEnabled={isValid}
                        action={handleOnSubmit}
                    />
                </div>
            </div>}
            <span className={styles.ErrorMessage}>{errorMessage}</span>
            <span className={styles.SuccessMessage}>{response}</span>
            <ul>
                <li>{`Please note that crypto transactions can take a period of ${transaction?.transactionType === "Buy" ? '10-15' : '15-20'} minutes for full confirmation`}</li>
                {transaction?.transactionType !== "Buy" && <li>You’ll be credited naira upon 3/4 confirmations.</li>}
                <li>You have successfully signed up and can log in with your phone number the next time to enjoy full features of your account.</li>
                <li>Need further help? Chat support on 09068056728</li>
            </ul>
        </div>}
    </OnBoardingCtnr>);
}

export default Processing;