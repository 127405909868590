import { Link } from "react-router-dom";
import SettingsHeader from "../../../../../Components/SettingsHeader/Index";
import styles from "./css/index.module.css";
import ArrowRight from "./../../../../../img/ArrowRight.png";
//import USD from "./../../../../../img/DollarSign.png";
//import NGN from "./../../../../../img/NairaSign.png";
import BTC from "./../../../../../img/BitcoinSign.png";
import SOL from "./../../../../../img/solana.png";
import USDT from "./../../../../../img/USDT.png";
import { USDT_NETWORKS, CURRENCIES, SOL_NETWORKS } from "../../../../../Utils/StaticData";

const CryptoWallet = () => {
    

    return(
    <div className={styles.Rates}>
        <SettingsHeader
         heading={"Crypto Wallet Address Settings"}
         backText={"Settings / Transactions "}
         backLink={"/Admin/Settings/Transactions"}
        />
        <div className={styles.RatesBody}>
            {/* <Link to={CURRENCIES[0].ShortCode}>
                <div>
                    <img src={USD} alt="Dollar Sign" />
                    <span>United States Dollars</span>
                </div>
                <img src={ArrowRight} alt="Forward Arrow" />
            </Link>
            <Link to={CURRENCIES[1].ShortCode}>
                <div>
                    <img src={NGN} alt="Naira Sign" />
                    <span>Nigerian Naira</span>
                </div>
                <img src={ArrowRight} alt="Forward Arrow" />
            </Link> */}
            <Link to={CURRENCIES[2].ShortCode}>
                <div>
                    <img src={BTC} alt="Bitcoin Sign" />
                    <span>Bitcoin</span>
                </div>
                <img src={ArrowRight} alt="Forward Arrow" />
            </Link>
           {/*  <Link to={CURRENCIES[3].ShortCode}>
                <div>
                    <img src={PM} alt="Perfect Money Sign" />
                    <span>Perfect Money</span>
                </div>
                <img src={ArrowRight} alt="Forward Arrow" />
            </Link> */}
            <Link to={`${CURRENCIES[4].ShortCode}-${USDT_NETWORKS[0]}`}>
                <div>
                    <img src={USDT} alt="USDT Sign" style={{width : "40px", height : "40px"}} />
                    <span>USDT {USDT_NETWORKS[0]}</span>
                </div>
                <img src={ArrowRight} alt="Forward Arrow" />
            </Link>
            <Link to={`${CURRENCIES[4].ShortCode}-${USDT_NETWORKS[1]}`}>
                <div>
                    <img src={USDT} alt="USDT Sign" style={{width : "40px", height : "40px"}} />
                    <span>USDT {USDT_NETWORKS[1]}</span>
                </div>
                <img src={ArrowRight} alt="Forward Arrow" />
            </Link>
            <Link to={`${CURRENCIES[5].ShortCode}-${USDT_NETWORKS[0]}`}>
                <div>
                    <img src={USDT} alt="USDT Sign" style={{width : "40px", height : "40px"}} />
                    <span>USDC {USDT_NETWORKS[0]}</span>
                </div>
                <img src={ArrowRight} alt="Forward Arrow" />
            </Link>
            <Link to={`${CURRENCIES[5].ShortCode}-${USDT_NETWORKS[1]}`}>
                <div>
                    <img src={USDT} alt="USDT Sign" style={{width : "40px", height : "40px"}} />
                    <span>USDC {USDT_NETWORKS[1]}</span>
                </div>
                <img src={ArrowRight} alt="Forward Arrow" />
            </Link>
            <Link to={`${CURRENCIES[6].ShortCode}-${SOL_NETWORKS[0]}`}>
                <div>
                    <img src={SOL} alt="Solana Sign" style={{width : "40px", height : "40px"}} />
                    <span>Solana {`(`}{SOL_NETWORKS[0]}{`)`}</span>
                </div>
                <img src={ArrowRight} alt="Forward Arrow" />
            </Link>
        </div>
    </div>
    );

}

export default CryptoWallet;