import { useContext, useEffect, useMemo, useState } from "react";
import styles from "./css/index.module.css";
import BTC from "./../../img/BTC.png";
import USDC from "./../../img/USDC.png"
import USDT from "./../../img/USDT.png";
import SOL from "./../../img/solana.png";
import ArrowDown from "./../../img/ArrowDown.png";
import Button from "../Button/Index";
import { GetRates, GetLatestFees, GetAddresses, GetBankAccountList } from "./../../Utils/GetData";
import { BASE_URL, BUY, GET_BANK_ACCOUNT_DETAILS, GET_CRYPTO_WALLET, GET_WALLET_AMOUNT, LIMITCHECK, SELL, SWAP } from "./../../Utils/Endpoint";
import PhoneInput from "../../Pages/OnBoarding/Components/PhoneInput";
import InputCtnr from "../InputCtnr/Index";
import Search from "../../img/PhoneSearch.png";
import Input from "../Input/Index";
import { useNavigate } from "react-router-dom";


const UnAuthTradeBlock = () => {

    const navigate = useNavigate()

    const TradeType = useMemo(() => ["Buy", "Sell", "Swap"], []);
    const Cryptos = useMemo(() => [
        {
            shortCode : "BTC",
            name : "Bitcoin",
            img : BTC
        },
        {
            shortCode : "USDT",
            name : "USDT",
            img : USDT
        },
        {
            shortCode : "USDC",
            name : "USDC",
            img : USDC
        },
        {
            shortCode : "SOL",
            name : "Solana",
            img : SOL
        }
    ], [])
    const Currency = ["NGN", "USD"];
    const [cryptoList, setCryptoList] = useState(Cryptos);
    const [activeTradeType, setType] = useState(TradeType[0]);
    const [showAssetOpt, setShowAssetOpt] = useState(false);
    const [showAssetOpt2, setShowAssetOpt2] = useState(false);
    const [showNetworkOpt, setShowNetworkOpt] = useState(false);
    const [showNetworkOpt2, setShowNetworkOpt2] = useState(false);
    const [asset, setAsset] = useState(null);
    const [asset2, setAsset2] = useState(null);
    const [network, setNetwork] = useState(null);
    const [network2, setNetwork2] = useState(null);
    const [amount, setAmount] = useState('');
    const [rates, setRates] = useState(null);
    const [fees, setFees] = useState(null);
    const [buyFee, setBuyFee] = useState(null);
    const [activeRate1, setActiveRate1] = useState(null);
    const [activeRate2, setActiveRate2] = useState(null);
    const [buyNairaRate, setBuyNairaRate] = useState(null);
    const [sellNairaRate, setSellNairaRate] = useState(null);
    const [buyCurrency, setBuyCurrency] = useState(Currency[1]);
    const [showBuyCur, setBuyCur] = useState(false);
    const [cryptoWallets, setCryptoWallets] = useState([]);
    const [sellAddress, setSellAddress] = useState(null);
    const [swapAddress, setSwapAddress] = useState(null);
    const [phoneInfo, setPhone] = useState({phoneNumber: ''});
    const [destAddress, setDestAddress] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [banks, setBanks] = useState(null);
    const [showBanks, setShowBanks] = useState(false);
    const [filteredBanks, setFilteredBanks] = useState(null);
    const [accountDetails, setAccountDetails] = useState({name : "", number: ""});
    const [activebank, setActiveBank] = useState(null);
    const [phoneCode, setCode] = useState('');


    let setNameHandler = (value) => {
        /*  setAccountDetails(prevState => {
             return {
                 ...prevState,
                 name : value
             }
         }); */
         console.log(value);
     }


    let setNumberHandler = (value) => {

        if(value.length < 11)
            setAccountDetails(prevState => {
                return {
                    ...prevState,
                    number : value
                }
            });
        
        if(value.length === 10)
            GetBankAccountDetails(value);
    }

    let GetBankAccountDetails = async (value) => {
        
        var response = await fetch(BASE_URL + GET_BANK_ACCOUNT_DETAILS,
            {
                method : "POST",
                body : JSON.stringify({
                    "accountNumber": value,
                    "bankCode": activebank.code,
                  }),
                headers : {
                    'content-type': 'application/json',
                   // "Authorization" : "Bearer " + AuthCtx.token
                }
            })

            let json = await response.json();
            console.log(json);

            if(response.ok){
                
                setAccountDetails(prevState => {
                    return {
                        ...prevState,
                        name : json?.data?.account_name
                    }
                });
                //setErrorMessage('');
                return null;
            }

            //setErrorMessage(json.statusText);
            return null;

    }

    let setAction = async () => {

        if(!isValid){
            return
        }

        const obj = {
            "phoneNumber": `${phoneCode}${phoneInfo.phoneNumber.charAt(0) === '0' ?
                    phoneInfo.phoneNumber.substring(1) : 
                    phoneInfo.phoneNumber}`,
        }

        if(activeTradeType === TradeType[0])
        {
            var convertedAmount = buyCurrency === Currency[0] ? parseFloat(amount) : parseFloat(amount * buyNairaRate.rate)
            obj.amount = convertedAmount
            obj.destinationAddress = destAddress
            obj.destinationNetworkType = network !== null ? network : null
            obj.destinationRateId = activeRate1.id
            obj.feeId = buyFee.id
            obj.destinationAssetId = asset.shortCode
            obj.buyNairaRateId = buyNairaRate.id
            obj.transactionType = TradeType[0]
        }
        else if(activeTradeType === TradeType[1])
        {
            obj.amount = parseFloat(amount * sellNairaRate.rate)
            obj.accountName = accountDetails.name
            obj.accountNumber = accountDetails.number
            obj.bankName = activebank.name
            obj.sourceNetworkType = network !== null ? network : null
            obj.sourceRateId = activeRate1.id
            obj.sellNairaRateId = sellNairaRate.id
            obj.sourceAssetId = asset.shortCode
            obj.transactionType = TradeType[1]
        }
        else if(activeTradeType === TradeType[2])
        {
            console.log(buyFee)
            obj.amount = parseFloat(amount * buyNairaRate.rate)
            obj.destinationAddress = destAddress
            obj.sourceNetworkType = network !== null ? network : null
            obj.sourceRateId = activeRate1.id
            obj.destinationRateId = activeRate2.id
            obj.destinationNetworkType = network2 !== null ? network2 : null
            obj.feeId = buyFee.id
            obj.sourceAssetId = asset.shortCode
            obj.destinationAssetId = asset2.shortCode
            obj.buyNairaRateId = buyNairaRate.id
            obj.sellNairaRateId = sellNairaRate.id
            obj.transactionType = TradeType[2]
        }

        //console.log(obj)
        //return

        localStorage.setItem("transaction", JSON.stringify(obj))

        var response = await fetch(BASE_URL + LIMITCHECK,
            {
                method : "POST",
                body : JSON.stringify({
                    "phoneNumber": `${phoneCode}${phoneInfo.phoneNumber.charAt(0) === '0' ?
        phoneInfo.phoneNumber.substring(1) : 
        phoneInfo.phoneNumber}`,
                    "amount": parseFloat(amount),
                    "currency": activeTradeType === TradeType[0] ? buyCurrency : Currency[1]
                  }),
                headers : {
                    'content-type': 'application/json',
                   // "Authorization" : "Bearer " + AuthCtx.token
                }
            })

            let json = await response.json();
            //console.log(json);

            if(response.ok){

                const {
                    data: {
                        overLimit,
                        userExist,
                        userId
                    }
                } = json
                
                if(overLimit){
                    if(userExist)
                        navigate("/Seamless",{
                            state: json.data
                        })
                    else
                        navigate("/Seamless",{
                            state: json.data
                        })
                }
                else
                {
                    navigate("/Seamless",{
                        state: json.data
                    })
                }
                return null;
            }

            //setErrorMessage(json.statusText);
            return null;
        
    }

    useEffect(() => {

        if(activeTradeType === TradeType[0] || activeTradeType === TradeType[1]){
            setIsValid(prev => asset?.shortCode === "USDT" ||asset?.shortCode === "USDC" ? 
            !!asset && !!network && !!amount && !!phoneInfo.phoneNumber 
            :
            !!asset && !!amount && !!phoneInfo.phoneNumber)
            if(activeTradeType === TradeType[1]){
                setIsValid(prev => prev && !!accountDetails.name && !!accountDetails.number && !!activebank)
            }
        }else if(activeTradeType === TradeType[2]){
            setIsValid(prev => asset?.shortCode === "USDT" ||asset?.shortCode === "USDC" ? 
            asset2?.shortCode === "USDT" ||asset2?.shortCode === "USDC" ?
            !!asset && !!network && !!amount && !!phoneInfo.phoneNumber && !!asset2 && !!network2 && !!destAddress
            :
            !!asset && !!amount && !!phoneInfo.phoneNumber && !!network && !!asset2 && !!destAddress
            :
            asset2?.shortCode === "USDT" ||asset2?.shortCode === "USDC" ?
            !!asset && !!amount && !!phoneInfo.phoneNumber && !!network2 && !!asset2 && !!destAddress
            :
            !!asset && !!amount && !!phoneInfo.phoneNumber && !!asset2 && !!destAddress)
            
        }

    },[activeTradeType, TradeType, asset, network, phoneInfo, amount, asset2, network2, destAddress, accountDetails, activebank])

    const Networks = [
        "ERC-20",
        "TRC-20"
    ]

    const SolNetworks = [
        "Solana Network"
    ]

    useEffect(() => {

        

        if(activeTradeType === TradeType[2] && asset === asset2){
            setAsset2(null)
        }
        else if(asset !== null)
            setCryptoList(prevState => Cryptos.filter(prev => asset?.shortCode !== prev.shortCode))
        else
            setCryptoList(Cryptos);

    },[Cryptos, asset, asset2, activeTradeType, TradeType])

    useEffect(() => {

        if(activeTradeType !== TradeType[2]){

            setAsset2(null);
            setNetwork2(null);

        }else if(asset?.shortCode !== Cryptos[1].shortCode || asset?.shortCode !== Cryptos[2].shortCode){
            setNetwork(null);
        }else if(asset2?.shortCode !== Cryptos[1].shortCode || asset?.shortCode !== Cryptos[2].shortCode){
            setNetwork2(null);
        }

    },[activeTradeType, TradeType, asset, asset2, Cryptos])

    useEffect(() => {
        let GetLatestRatesAndFees = async () => {
            const [
                resRates,
                resFees,
                crypWallets,
                banks
            ] = await Promise.all([
                GetRates(),
                GetLatestFees(),
                GetAddresses(),
                GetBankAccountList() 
            ])
            //let resRates = await GetRates();
            //let resFees = await GetLatestFees();
            //let crypWallets = await GetAddresses();
            //let banks = await GetBankAccountList();
            setRates(resRates);
            setFees(resFees);
            setCryptoWallets(crypWallets);
            setBanks(banks);
        }

        GetLatestRatesAndFees();
    },[]);


    useEffect(() => {

        if(asset !== null && rates !== null)
            setActiveRate1(rates.filter(x => x.currencyShort === asset.shortCode)[0]);

        if(asset2 !== null && rates !== null)
            setActiveRate2(rates.filter(x => x.currencyShort === asset2.shortCode)[0]);

        if(rates !== null && activeTradeType === TradeType[0] && asset !== null)
            setBuyNairaRate(rates.filter(x => x.currencyShort === `${asset.shortCode}-${TradeType[0]}`)[0])

        if(rates !== null && activeTradeType === TradeType[1] && asset !== null)
            setSellNairaRate(rates.filter(x => x.currencyShort === `${asset.shortCode}-${TradeType[1]}`)[0])

        if(rates !== null && activeTradeType === TradeType[2] && asset !== null && asset2 !== null){
            setBuyNairaRate(rates.filter(x => x.currencyShort === `${asset2.shortCode}-${TradeType[0]}`)[0])
            setSellNairaRate(rates.filter(x => x.currencyShort === `${asset.shortCode}-${TradeType[1]}`)[0])
        }

    },[asset,asset2,rates,TradeType,activeTradeType,amount])

    useEffect(() => {
        if(cryptoWallets.length !== 0){

            if(activeTradeType === TradeType[1] && asset !== null && asset?.shortCode === Cryptos[0].shortCode)
                setSellAddress(cryptoWallets.filter(x => x.currencyShort === `${asset.shortCode}`)[0].address)
            else if (activeTradeType === TradeType[1] && asset !== null && asset?.shortCode !== Cryptos[0].shortCode && network !== null)
                setSellAddress(cryptoWallets.filter(x => x.currencyShort === `${asset.shortCode}-${network}`)[0].address)

            if(activeTradeType === TradeType[2] && asset !== null && asset?.shortCode === Cryptos[0].shortCode)
                setSwapAddress(cryptoWallets.filter(x => x.currencyShort === `${asset.shortCode}`)[0].address)
            else if (activeTradeType === TradeType[2] && asset !== null && asset?.shortCode !== Cryptos[0].shortCode && network !== null)
                setSwapAddress(cryptoWallets.filter(x => x.currencyShort === `${asset.shortCode}-${network}`)[0].address)

        }
    },[cryptoWallets, asset, rates, activeTradeType, TradeType, Cryptos, network])

    useEffect(() => {

            setBuyFee(prev => {
                if(activeTradeType === TradeType[0]){
                    if(asset === null){
                        return null
                    }
                    else if(asset.shortCode === Cryptos[0].shortCode && fees !== null){
                        return fees.filter(fee => fee.currencyShort ===`${asset.shortCode}`)[0]
                    }
                    else if(asset.shortCode !== Cryptos[0].shortCode && network !== null && fees !== null){
                        return fees.filter(fee => fee.currencyShort ===`${asset.shortCode}-${network}`)[0] 
                    }                    
                    else
                    {
                        return null
                    }
                }else if(activeTradeType === TradeType[2]){
                    if(asset2 === null){
                        return null
                    }
                    else if(asset2.shortCode === Cryptos[0].shortCode && fees !== null){
                        return fees.filter(fee => fee.currencyShort ===`${asset2.shortCode}`)[0]
                    }
                    else if(asset2.shortCode !== Cryptos[0].shortCode && network2 !== null && fees !== null){
                        return fees.filter(fee => fee.currencyShort ===`${asset2.shortCode}-${network2}`)[0]
                    }
                    else
                    {
                        return null
                    }
                }else{
                    return null
                }
            })

    },[asset, asset2, activeTradeType, TradeType, fees, Cryptos, network, network2])


    return(
        <>
            <div className={styles.TradeBlock}>
                <div>
                    <button 
                        className={activeTradeType === TradeType[0] ? styles.IsActive : "" }
                        onClick={() => {setType(TradeType[0])}}
                    >Buy</button>
                    <button 
                        className={activeTradeType === TradeType[1] ? styles.IsActive : "" }
                        onClick={() => setType(TradeType[1])}
                    >Sell</button>
                    <button 
                        className={activeTradeType === TradeType[2] ? styles.IsActive : "" }
                        onClick={() => setType(TradeType[2])}
                    >Swap Your Asset</button>
                </div>
                <div>
                    <div className={styles.phoneNumber} >
                    <label>Phone Number</label>
                        <PhoneInput
                            value={phoneInfo.phoneNumber}
                            setPhone={setPhone}
                            setCode={setCode}
                        />
                    </div>
                    <div className={`${styles.Asset} ${styles.TopMargin}`}>       
                        <label>Asset</label>
                        <div className={styles.AssetCtnr} onClick={() => setShowAssetOpt(prevState => !prevState)}>
                            <div >
                                <div>
                                    {asset !== null ? 
                                    <>
                                        <img src={asset.img} alt="Active Coin" className={styles.coinSize}/>
                                        <label>{asset.name}</label>
                                    </> :
                                    <label>Select Asset</label>
                                    }
                                </div>
                                <img src={ArrowDown} alt="Arrow Down"/>
                            </div>
                            {showAssetOpt &&
                            <div>
                                {cryptoList.map((crypto, index) => (
                                    <div key={index} onClick={() => {setAsset(crypto);}}>
                                        <img src={crypto.img} alt="Active Coin" className={styles.coinSize}/>
                                        <label>{crypto.name}</label>
                                    </div>
                                ))}
                            </div>}
                        </div>
                    </div>
                    {(asset?.name === "USDT" || asset?.name === "USDC" || asset?.name === "Solana") &&
                    <div className={`${styles.Asset} ${styles.TopMargin}`}>
                        <label>Network Type</label>
                        <div className={styles.AssetCtnr} onClick={() => setShowNetworkOpt(prevState => !prevState)}>
                            <div >
                                <div>
                                    {network !== null ? 
                                    <>
                                        <label>{network}</label>
                                    </> :
                                    <label>Select Network</label>
                                    }
                                </div>
                                <img src={ArrowDown} alt="Arrow Down"/>
                            </div>
                            {showNetworkOpt &&
                            <div>
                                {asset?.name === "Solana" ?
                                 SolNetworks.map((netwk, index) => (
                                    <div key={index} onClick={() => setNetwork(netwk)}>
                                        <label>{netwk}</label>
                                    </div>
                                ))
                                :
                                asset?.name === "USDC" ?
                                 Networks.filter(x => x === "ERC-20").map((netwk, index) => (
                                    <div key={index} onClick={() => setNetwork(netwk)}>
                                        <label>{netwk}</label>
                                    </div>
                                ))
                                :
                                Networks.map((netwk, index) => (
                                    <div key={index} onClick={() => setNetwork(netwk)}>
                                        <label>{netwk}</label>
                                    </div>
                                ))}
                            </div>}
                        </div>
                    </div>}
                    {activeTradeType === TradeType[2] &&
                    <div className={`${styles.Asset} ${activeTradeType === TradeType[2] ? styles.SwapMargin : ""}`}>
                        <label>Swap for</label>
                        <div className={styles.AssetCtnr} onClick={() => setShowAssetOpt2(prevState => !prevState)}>
                                <div >
                                    <div>
                                        {asset2 !== null ? 
                                        <>
                                            <img src={asset2.img} alt="Active Coin" className={styles.coinSize}/>
                                            <label>{asset2.name}</label>
                                        </> :
                                        <label>Select Asset</label>
                                        }
                                    </div>
                                    <img src={ArrowDown} alt="Arrow Down"/>
                                </div>
                                {showAssetOpt2 &&
                                <div>
                                    {cryptoList.map((crypto, index) => (
                                        <div key={index} onClick={() => {setAsset2(crypto);}}>
                                            <img src={crypto.img} alt="Active Coin" className={styles.coinSize}/>
                                            <label>{crypto.name}</label>
                                        </div>
                                    ))}
                                </div>}
                            </div>
                    </div>}
                    {(asset2?.name === "USDT" || asset2?.name === "USDC" || asset2?.name === "Solana") &&
                    <div className={styles.Asset}>
                        <label>Network Type</label>
                        <div className={styles.AssetCtnr} onClick={() => setShowNetworkOpt2(prevState => !prevState)}>
                            <div >
                                <div>
                                    {network2 !== null ? 
                                    <>
                                        <label>{network2}</label>
                                    </> :
                                    <label>Select Network</label>
                                    }
                                </div>
                                <img src={ArrowDown} alt="Arrow Down"/>
                            </div>
                            {showNetworkOpt2 &&
                            <div>
                                {asset2?.name === "Solana" ?
                                 SolNetworks.map((netwk, index) => (
                                    <div key={index} onClick={() => setNetwork2(netwk)}>
                                        <label>{netwk}</label>
                                    </div>
                                ))
                                :
                                asset2?.name === "USDC" ?
                                 Networks.filter(x => x === "ERC-20").map((netwk, index) => (
                                    <div key={index} onClick={() => setNetwork2(netwk)}>
                                        <label>{netwk}</label>
                                    </div>
                                ))
                                :
                                Networks.map((netwk, index) => (
                                    <div key={index} onClick={() => setNetwork2(netwk)}>
                                        <label>{netwk}</label>
                                    </div>
                                ))}
                            </div>}
                        </div>
                    </div>}
                    {(activeTradeType === TradeType[2] || activeTradeType === TradeType[0]) &&
                    <div className={`${styles.Asset} ${styles.DestAddress} ${styles.TopMargin}`}>
                    <label>Destination Address</label>
                        <div>
                            <input type="text" value={destAddress} onChange={e => setDestAddress(e.target.value)} />
                        </div>
                    </div>}
                    <div className={`${styles.Amount} ${activeTradeType === TradeType[2] ? styles.SwapMargin : ""}`}>
                        <label>Amount</label>
                        <div> 
                            <label className={styles.Currency} onClick={() => {if(activeTradeType === TradeType[0]) setBuyCur(prev => !prev)}}>
                                {activeTradeType === TradeType[0] ? 
                                <>
                                    <span>{buyCurrency}</span> 
                                    <img src={ArrowDown} alt="Arrow Down" className={styles.currencySpace}/>
                                </>
                                 : 'USD'}
                            {showBuyCur &&
                            <div>
                                {Currency.map((cur, index) => 
                                    (<label key={index} onClick={() => setBuyCurrency(cur)}>{cur}</label>))}
                            </div>}
                            </label>
                            <input type="number" onChange={(e) => setAmount(e.target.value)} value={amount} />
                        </div>
                    </div>
                    {activeTradeType === TradeType[1] &&
                    <div className={styles.AddBankAccountBody}>
                        <div>
                            <InputCtnr label={"Bank Name"}>
                                <div className={styles.BankNameDropdown}>
                                    <div onClick={() => {setShowBanks(prevState => !prevState);setFilteredBanks(banks)}}>
                                        <span>{activebank === null ? "Select a bank..." : activebank.name}</span>
                                        <img src={ArrowDown} alt="Arrow down" />
                                    </div>
                                    {showBanks &&
                                    <div>
                                        <div>
                                            <img src={Search} alt="Phone Search"/>
                                            <input 
                                                type={"search"} 
                                                placeholder={"Search"} 
                                                onChange={(e) => setFilteredBanks(prevState => banks.filter(str => str.name.toLowerCase().includes(e.target.value.toLowerCase())))} />
                                        </div>
                                        {filteredBanks !== null && filteredBanks.map((bankInfo, index) => (
                                            <div key={index} onClick={() => {setActiveBank(bankInfo);setShowBanks(false);setFilteredBanks(banks)}}>
                                                <span>{bankInfo.name}</span>
                                            </div>
                                        ))}
                                    </div>}
                                </div>
                            </InputCtnr>
                            <InputCtnr label={"Account Number"}>
                                <Input 
                                    value={accountDetails.number}
                                    setChange={setNumberHandler}
                                    type={"tel"}
                                />
                            </InputCtnr>
                            <InputCtnr label={"Account Name"}>
                                <Input
                                    value={accountDetails.name}
                                    setChange={setNameHandler}
                                    type={"text"} 
                                />
                            </InputCtnr>
                        </div>
                    </div>
                    }
                    {activeTradeType === TradeType[0] &&
                    <div className={styles.AccountLimit}>
                        <p>Your account trading limit is $5,000</p>
                        <button>Verify Now</button>
                    </div>}
                    {activeTradeType === TradeType[0] &&
                    <div className={styles.Summary}>
                        <div>
                            <span>Total est.</span>
                            <span>{!buyNairaRate || !activeRate1 || amount === '' ? "---" : `${buyCurrency === Currency[0] ? (amount/(activeRate1.rate * buyNairaRate.rate)).toFixed(8) : (amount/(activeRate1.rate)).toFixed(8)} ${asset.shortCode}`}</span>
                        </div>
                        <div>
                            <span>Exchange rate</span>
                            <span>NGN {!buyNairaRate ? " ---" : buyNairaRate.rate} / USD</span>
                        </div>
                        <div>
                            <span>Fee</span>
                            <span>{buyCurrency} {!buyNairaRate || !buyFee ? " ---" : buyCurrency === Currency[0] ? buyFee.feeCharge :  (buyFee.feeCharge/buyNairaRate.rate).toFixed(2)}</span>
                        </div>
                        <div>
                            <b>Transfer to the bank below</b>
                        </div>
                        <div>
                            <span>Amount est.</span>
                            <span>NGN {!!amount && !!buyNairaRate? buyCurrency === Currency[0] ? parseFloat(+amount + buyFee.feeCharge) : parseFloat(+(amount * buyNairaRate.rate) + buyFee.feeCharge) : "---"}</span>
                        </div>
                        <div>
                            <span>Bank name</span>
                            <span>PROVIDUS BANK</span>
                        </div>
                        <div>
                            <span>Account number</span>
                            <span>1304369844</span>
                        </div>
                        <div>
                            <span>Account name</span>
                            <span>YGX Meerchant</span>
                        </div>
                        <div>
                            <span><b>NB:</b> do not add any crypto reference e.g crypto, btc, usdt to your bank transfer reference or note.</span>
                        </div>
                    </div>}
                    {activeTradeType === TradeType[1] &&
                    <div className={styles.Summary}>
                        <div>
                            <span>You will receive est.</span>
                            <span>{!sellNairaRate || amount === '' ? "---" : parseFloat(amount * sellNairaRate.rate).toFixed(2)} NGN</span>
                        </div>
                        {asset !== null &&
                        <div>
                            <span>{asset.shortCode} Amount</span>
                            <span>{!sellNairaRate || amount === '' ? "---" : parseFloat(amount / activeRate1.rate).toFixed(8)} {asset.shortCode}</span>
                        </div>}
                        <div>
                            <span>Exchange rate</span>
                            <span>NGN {!sellNairaRate ? "---" : parseFloat(sellNairaRate.rate).toFixed(2)} / USD</span>
                        </div>
                        {asset !== null &&
                            <>
                                <div>
                                    <b>{`Transfer to the ${asset.shortCode}  address below`}</b>
                                </div>
                                <div>
                                    <span>{sellAddress}</span>
                                </div>
                            </>
                        }
                    </div>}
                    {activeTradeType === TradeType[2] &&
                    <div className={styles.Summary}>
                        {asset !== null &&
                            <>
                                <div>
                                    <b>{`Transfer to the ${asset.shortCode}  address below`}</b>
                                </div>
                                <div>
                                    <span>{swapAddress}</span>
                                </div>
                            </>
                        }
                    </div>
                    }
                    <Button
                        text={"I have transferred."}
                        isEnabled={isValid}
                        action={setAction}
                    />
                </div>
            </div>
        </>
    )

}

export default UnAuthTradeBlock;